import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from 'lib/api2';
import useNotification from 'components/useNotification';
import ContextCommand from 'components/ContextCommand';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { rimuoviNull, nullZLSTabella } from 'lib/dati';
import Loader from 'components/Loader';
import Grid from '@mui/material/Grid';
import FormTitle from 'components/FormTitle';
import FormTextField from 'components/FormTextField';
import { Editor } from '@tinymce/tinymce-react';
import DialogCampi from 'components/DialogCampi';


export default function ContrattiModelliDettagli({setTitle,setContextCommands}) {
  const { id } = useParams();
  const { pushNotification } = useNotification();
  const { control, handleSubmit, /*trigger, getValues, setValue,*/ reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
  const [ isLoaderOpen, setLoaderOpen ] = useState(false);
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [isDialogCampiOpen, setDialogCampiOpen] = useState(false);


  const salva = useCallback(modello=>{
		const data = nullZLSTabella([modello]);
		api.post("/contratti/modello", data).then(()=>{
			pushNotification("Dati salvati", "success");
			navigate("/configurazione/contratti/modelli/"+modello.idModelloContratto+"/dettaglio");
		}, async error=>{
			const descrizioneErrore = await error.text();
			pushNotification("Errore nel salvataggio: " + descrizioneErrore, "error");
		});
	}, [navigate, pushNotification]);

  useEffect(() => {
    api.get(`/contratti/modelli/${id}`).then(data=>{
      if (data.length===0)	{
        alert('Errore nel caricamento dei dati');
      } else {
        const modello = rimuoviNull(data)[0];
        modello._action="e";
        reset(modello);
      }
      setLoaderOpen(false);
    });
  }, [id, reset]);

	useEffect(() => {
	    setTitle('Configurazione - Modello dettaglio');
  }, [setTitle]);

  useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/configurazione/contratti/modelli") }>Indietro</ContextCommand>,
			<ContextCommand key="2" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>
		]);
  	}, [setContextCommands, handleSubmit, navigate, salva] );

	return (
		<form>
			{isDialogCampiOpen && <DialogCampi onClose={() => setDialogCampiOpen(false)} />}
			<Loader open={isLoaderOpen} />
			<Grid container spacing={1}>
				<FormTitle variant="h6">Dettagli</FormTitle>
				<FormTextField xs={12} md={4} control={control} name="idModelloContratto" label="ID" disabled />
				<FormTextField xs={12} md={8} control={control} name="descrizione" label="Descrizione" required />
				<Grid item xs={12}>
				<Controller
					name='modello'
					control={control}
					render={({ field }) =>	{
						const { onChange, ...newField } = field;
						return (
							<>
								<Editor
								apiKey='tzv8y8j6htf9o0k4pwerwulyij70qnvox0fkk3mb58epx3o1'
								onInit={(evt, editor) => editorRef.current = editor}
								init={{
									height: 500,
									menubar: false,
									plugins: [
										'code ',
										'textcolor',
										'advlist autolink lists link image charmap print preview anchor',
										'searchreplace visualblocks code fullscreen',
										'insertdatetime media table paste code help wordcount'
									],
									toolbar: 'undo redo | fontfamily fontsize | ' +
										'bold italic underline forecolor backcolor | alignleft aligncenter ' +
										'alignright alignjustify | bullist numlist outdent indent | ' +
										'removeformat | code | listaPlanceholder',
									content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 11pt }',
									font_size_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
									setup: function (editor) {
										editor.ui.registry.addButton('listaPlanceholder', {
											text: 'Lista campi disponibili',
											onAction: function (_) {
												setDialogCampiOpen(true);
											}
										});
									}
								}}
								{...newField}
								onEditorChange={(newValue) => {
									onChange(newValue);
								}}
								/>
							</>
						)
					}
					}/>
				</Grid>
			</Grid>
    	</form>
	);
}