import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

export default function Filtro({filtra})    {
    const [filtroDescrizione, setFiltroDescrizione] = useState('');

    const handleKeyDown=key=>{
        if (key.keyCode===13) filtra(filtroDescrizione);
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item md={11} xs={10}>
                    <TextField label="Ricerca" value={filtroDescrizione} onChange={e=>setFiltroDescrizione(e.target.value.toLowerCase())} fullWidth style={{marginBottom:'20px'}} onKeyDown={handleKeyDown} />
                </Grid>
                <Grid item md={1} xs={3}>
                    <Button onClick={()=>filtra(filtroDescrizione)} style={{height:'75%'}} size="large" variant="outlined" fullWidth>Filtra</Button>
                </Grid>
            </Grid>
        </div>
    )
}