import React, {useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import DrawerMenu from './DrawerMenu';
import useLayoutStyles from './../../appearance/layoutStyles';

export default function Nav({title,contextCommands}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useLayoutStyles();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(()=>{
    document.title=title;
  }, [title]);

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {title}
          </Typography>
          {contextCommands.map(command=>command)}
        </Toolbar>
      </AppBar>
      {}
      <DrawerMenu mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
    </div>
  );
}
