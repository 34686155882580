import React from 'react';
import Icon from './../components/Icon';
import Button from '@mui/material/Button';

export default function AppBarButton(props) {
  return (
    <Button
      color="inherit"
      startIcon={<Icon icon={props.icon} size="1.4rem" />}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(0, 255, 0, 0.4)', // Colore al passaggio del mouse
        },
        border: '1px solid rgba(0, 0, 0, 0.12)', // Bordo del pulsante
        marginRight: '38px', // Spazio a destra del pulsante
        marginLeft: '38px', // Spazio a sinistra del pulsante
      }}
      variant = "outlined" // Pulsante con bordo
      {...props}
    >
      {props.children}
    </Button>
  );
}