import React, { useEffect, useRef } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function Banche({setTitle,setContextCommands}) {
	const dettagli=useRef();

	const columns=[
		{ field: 'idBanca', headerName: 'ID', width: 90, type:'number' },
    { field: 'descrizioneBanca', headerName: 'Banca', editable:true, flex:true },
		{ field: 'bancaDefault', headerName: 'Default', width: 90, editable:true, renderCell: params=>dettagli.current.setOption(params) },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Banche');
  }, [setTitle]);

	return <SimpleDataGrid
			ref={dettagli}
      idField="idBanca"
      getUrl="/banche"
      postUrl="/banche"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
