import React, { useState, useEffect } from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PrintIcon from '@mui/icons-material/Print';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import cellSelect from '../components/SimpleDataGrid/cellSelect';
import api from '../lib/api2';
import useNotification from 'components/useNotification';
import { GridActionsCellItem } from '@mui/x-data-grid';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useReport from 'components/Report/useReport.js';
import Grid from '@mui/material/Grid';
import FormTitle from 'components/FormTitle';
import SimpleDataGrid from 'components/SimpleDataGrid';
import moment from 'moment';
import { DateTime } from "luxon";

export default function ScadenzeContratto({idContratto, salvaScadenze}) {
    const [selectedScadenze,setSelectedScadenze]=useState([]);
	const [documentiTipi,setDocumentiTipi]=useState([]);
	const { pushNotification } = useNotification();
	const { openReport } = useReport();
	const dataLimite = moment().add(2, 'years').format('YYYY-MM-DD');

    useEffect(()=>{
        api.get('/documenti/tipi').then(data=>{
			setDocumentiTipi(data);
		});
    },[]);

    const pagamentoDocumento = documento=>{
		const oggi = DateTime.now().toFormat('yyyy-MM-dd');
		const postData = {idDocumento:documento, pagata:1, dataPagamento:oggi, metodoPagamento:"MP08", _action:'e'};
		api.post('/documenti', postData).then(data=>{
			pushNotification("Segnato come pagato", "success");
		}, error=>{
			pushNotification("Errore", "error");
		});
	}

    const emettiFattura = scadenza => {
		api.post("/contratti/genera/fatture", [scadenza]).then(r=>{
			pushNotification("Fatture create correttamente", "success");
		}, error=>{
			pushNotification("Errore nella creazione della fattura", "error");
		});
	}

    const columnsScadenze = [
		{ field: 'idScadenza', headerName: 'ID', width: 80 },
		{ field: 'contratto', editable: true, hide:true, headerName: 'Contratto', width: 80 },
		{ field: 'denominazioneCliente', headerName: 'Nominativo',  flex:1 },
		{ field: 'denominazioneStrumento', headerName: 'Strumento', width: 350 },
		{ field: 'dataScadenza', headerName: 'Data scadenza', editable: true,  width: 120, valueFormatter: v=>moment(v.value).format('DD/MM/YYYY')},
		{ field: 'importo', headerName: 'Importo', width: 200, editable: false,  type:'number', valueFormatter: params=>params.value===''?'--Auto--':params.value },
		{ field: 'tipoDocumento', headerName: 'Documento da creare', editable: true, width: 200, ...cellSelect(documentiTipi,'codiceTipoDocumento','descrizioneTipoDocumento') },
		{ field: 'descrizioneDocumento', headerName: 'Documento', width: 200 },
		{ field: 'pagata', headerName: 'Pagata', width: 80, align: "center", valueGetter:params=>params.row.idDocumento ? ( (params.value === "1") ? "SI" : "NO" ) : '' },
		{ field: '_1', headerName: '', width: 35, type:'actions', getActions: params => [
			<GridActionsCellItem label='' icon={<OpenInNewIcon/>} onClick={()=>window.open("/documenti/"+params.row.idDocumento)} disabled={!params.row.idDocumento} />,
		]},
		{ field: '_2', headerName: '', width: 35, type:'actions', getActions: params => [
			<GridActionsCellItem label='' icon={<PrintIcon />} onClick={()=>openReport("/report/documenti/"+params.row.idDocumento)}  disabled={!params.row.idDocumento} />,
		]},
		{ field: '_3', headerName: '', width: 35, type:'actions', getActions: params => [
			<GridActionsCellItem label='' icon={<PointOfSaleIcon />} onClick={()=>pagamentoDocumento(params.row.idDocumento)}  disabled={!params.row.idDocumento || params.row.pagata==="1"} />,
		]},
		{ field: '_4', headerName: '', width: 35, type:'actions', getActions: params => [
			<GridActionsCellItem label='' icon={<NoteAddIcon />} onClick={()=>emettiFattura(params.row.idScadenza)}  disabled={params.row.idDocumento!==''} />,
		]},
	]

    return 	(			
        <>
            <FormTitle variant="h6">Scadenze</FormTitle>
            <Grid item xs={12} >
                <SimpleDataGrid
                    idField="idScadenza"
					getUrl={"/contratti/scadenze?idContratto="+idContratto+"&dataScadenza="+dataLimite+"|<&sort=dataScadenza"}
                    postUrl='/contratti/scadenze'
                    columns={columnsScadenze}
                    allowAdd={false}
                    newRowTemplate={{contratto:idContratto,tipoDocumento:'TD01'}}
                    allowDelete={false}
                    upHandleSalva={salvaScadenze}
                    gridProps={{
                        onSelectionModelChange: selected => setSelectedScadenze(selected),
                        rowSelectionModel:selectedScadenze,
                        disableMultipleRowSelection:true
                    }}
                />
            </Grid>
        </>
    )

} 