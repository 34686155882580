import React, {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import DialogBanche from './../components/DialogBanche';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export default function FormBancaWrapper({name, label, control, setValue, xs=12, md=6}) {
    const handleBancaSelect = (banca) => {
        if (banca && banca.iban) {
            setValue('iban', banca.iban);
        }
    };

    return (
        <Grid item xs={xs} md={md}>
            <Controller name={name} control={control} render={({ field: { onChange, value } }) =>
                <FormBanca onChange={onChange} value={value} label={label} onBancaSelect={handleBancaSelect} />
            } />
        </Grid>
    )
}

function FormBanca({ value, onChange, label, onBancaSelect }) {
    const [isDialogBancheOpen, setDialogBancheOpen] = useState(false);
    const [descrizione, setDescrizione] = useState('');

    const handleBancheChange = banca => {
		setDialogBancheOpen(false);
        if (banca)
            onChange(banca);
            onBancaSelect(banca);
	}

    useEffect(()=>{
        setDescrizione('');
        if (value) {
            setDescrizione(value);
        }
    }, [value])

    return (
        <React.Fragment>
            {isDialogBancheOpen && <DialogBanche onSelect={handleBancheChange} /> }
            
            <TextField disabled={true} value={descrizione} label={label} fullWidth InputProps={{endAdornment: (
                <Stack direction="row">
                    <IconButton sx={{ color: "secondary.main" }} onClick={()=>setDialogBancheOpen(true)}>
                        <EditIcon />
                    </IconButton>
                </Stack>
                ),
            }}/>
        </React.Fragment> 
    )
}