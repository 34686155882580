import React from 'react';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Icon from './Icon';

const FormSelect=({name,multiple=false,defaultValue=(multiple?[]:''),label,rules,xs=12,md=6,control,options,disabled,valueField='value',labelField='label',iconField='',style={}})=>(
  <Grid item xs={xs} md={md} style={{marginBottom:5}}>
    <Controller
      name={name}
      control={control}
      // rules={{required:true}}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => 
        <FormControl fullWidth error={!!fieldState.error} disabled={disabled} style={style} >
          <InputLabel>{label}</InputLabel>
          {(Array.isArray(options) && options.length>0) &&
            <Select label={label} fullWidth {...field} multiple={multiple}>
              {options.map(o=>
                <MenuItem value={o[valueField]} key={o[valueField]}>
                  {iconField && o[iconField] && <span><Icon icon={o[iconField]} size='1.2em' />&nbsp;</span> } {o[labelField]}
                </MenuItem>
              )}
            </Select>
          }
          {(!Array.isArray(options) || options.length===0) &&
            <OutlinedInput variant='outlined' {...field} />
          }
        </FormControl>
      }
    />
  </Grid>)


  export default FormSelect;
