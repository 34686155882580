import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller  } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import api from '../lib/api2';
import Grid from '@mui/material/Grid';
import ContextCommand from 'components/ContextCommand';
import useNotification from 'components/useNotification';
import SimpleDataGrid from 'components/SimpleDataGrid';
import { DateTime } from "luxon";
import FormTextField from 'components/FormTextField';
import FormTitle from 'components/FormTitle';
import Loader from '../components/Loader';
import { nullZLSTabella } from 'lib/dati';
import Button from '@mui/material/Button';
import Archivio from 'components/Archivio';
import AggiungiDestinatari from './AggiungiDestinatari';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export default function Mailing({setTitle, setContextCommands}) {
	const { id } = useParams();
	const navigate = useNavigate();
	const [isFiltroOpen, setFiltroOpen] = useState(false);
	const { pushNotification } = useNotification();
	const editorRef = useRef(null);
	const { control, handleSubmit, /*watch, trigger,*/ /*getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const [ isLoaderOpen, setLoaderOpen] = useState(false);
	const [ destinatari, setDestinatari ] = useState([]);
	const [ destinatariTmp, setDestinatariTmp ] = useState([]);
	const salvaDestinatari = React.useRef();

	const columnsDestinatari = [
		{ field: 'idDestinatario', headerName: 'ID', editable:true, hide: true},
		{ field: 'mailing', headerName: 'mailing', editable:true, hide: true},
		{ field: 'email', headerName: 'E-mail',  flex:1, editable:true },
		// { field: 'cliente', headerName: 'Cliente',  width: 90},
		{ field: 'esito', headerName: 'Esito',  width: 120},
		{ field: 'dataInvio', headerName: 'Data invio', width: 170, type:'date', valueGetter:params=>(params.value) ? DateTime.fromSQL(params.value).toFormat('dd-MM-yyyy HH:mm:ss') : "" },
	]

	useEffect(() => {
		setLoaderOpen(true);
  	}, []);

	useEffect(() => {
		api.get(`/mailing/${id}/destinatari?sort=email`).then(data=>{
			setDestinatari(data);
			setDestinatariTmp(data);
		});
  	}, [id]);

	useEffect(()=>{
		if (id==='new') {
			setValue('_action', 'n');
			setValue('data', DateTime.now().toFormat('yyyy-MM-dd'));
			setLoaderOpen(false);
		} else {
			api.get(`/mailing/${id}`).then(data=>{
				if (data.length!==1) {
					alert('Errore nel caricamento dei dati');
				} else {
					const mailing = data[0];
					reset(mailing);
					setTitle("Mailing - ID: "+mailing.idMailing);
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue, reset, setTitle]);

	const salva = useCallback(data=>{
		var array = [];
		array.push(data);
		if (id === 'new') {
			api.post("/mailing", array).then(r=>{
				navigate("/mailing/"+JSON.parse(r));
			}, error=>{
				pushNotification("Errore nel salvataggio della mailing", "error");
			});
		} else {
			const json = nullZLSTabella(array);
			api.put("/mailing/"+data.idMailing, json).then(r=>{
				salvaDestinatari.current();
				api.get(`/mailing/${id}/destinatari?sort=email`).then(data=>{
					setDestinatari(data);
					setDestinatariTmp(data);
				});
				pushNotification("Dati salvati", "success");
				if (r===true)
					navigate("/mailing/"+data.idMailing);
			}, error=>{
				pushNotification("Errore nel salvataggio della mailing", "error");
			});
		}
	}, [id, navigate, pushNotification]);

	const invia = useCallback(data=>{
		api.post("/mailing/"+data.idMailing+"/destinatari/inviomail", nullZLSTabella(destinatari)).then(d=>{
			if (destinatari.esito) {
				api.get("/mailing/"+data.idMailing+"/destinatari?sort=email").then(data=>{
					setDestinatari(data);
					setDestinatariTmp(data);
				});
				pushNotification("Mail inviate", "success");
			}
			pushNotification("Mail inviate alle nuove mail", "success");
		}, error=>{
			pushNotification("Errore nell'invio delle mail: ", "error");
		});
	}, [pushNotification, destinatari]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/mailing") }>Indietro</ContextCommand>,
			<ContextCommand key="2" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>,
			<ContextCommand key="3" icon="send" onClick={ handleSubmit(data=>invia(data)) }>Invia mail</ContextCommand>
		]);
  	}, [setContextCommands, handleSubmit, navigate, salva, invia] );
	
	  const handleDestinatariChange = data => {
		setFiltroOpen(false);
		if (data) {
		  const newDestinatari = [...data].map(([idRecapito, riga]) => ({
			idDestinatario: 'new' + idRecapito,
			email: riga.recapito,
			mailing: id,
			_action: 'n',
		  }));
	  
		  // Filtra i nuovi destinatari che non sono già presenti nella lista esistente
		  const filteredDestinatari = newDestinatari.filter(newDest => {
			return !destinatari.some(dest => dest.email === newDest.email);
		  });
	  
		  setDestinatari(destinatariTmp.concat(filteredDestinatari));
		}
	};

	return (
		<form>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={1}>
				<FormTitle variant="h6">Oggetto</FormTitle>
				<FormTextField xs={12} md={12} control={control} name="subject" type="text" required />
				<FormTitle variant="h6">Messaggio</FormTitle>
				<Grid item xs={12}>
				<AggiungiDestinatari open={isFiltroOpen} onChange={handleDestinatariChange} />
				<Controller
					name='body'
					control={control}
					render={({ field }) =>	{
						const { onChange, ...newField } = field;
						return (
							<Editor
							apiKey='tzv8y8j6htf9o0k4pwerwulyij70qnvox0fkk3mb58epx3o1'
							
							onInit={(evt, editor) => editorRef.current = editor}
							init={{
								height: 500,
								menubar: false,
								plugins: [
									'textcolor',
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen',
									'insertdatetime media table paste code help wordcount'
								],
								toolbar: 'undo redo | fontfamily fontsize | ' +
									'bold italic underline forecolor backcolor | alignleft aligncenter ' +
									'alignright alignjustify | bullist numlist outdent indent | ' +
									'removeformat | listaPlanceholder',
								content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 11pt }',
								font_size_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
							}}
							{...newField}
							onEditorChange={(newValue) => {
								onChange(newValue);
							}}
						/>
						)
					}
					

					}/>
				</Grid>

				<FormTitle variant="h6" style={{marginTop:'20px'}}>File allegati alla mailing</FormTitle>
				<Archivio tipo="mailing" id={id} />

				<FormTitle variant="h6" style={{marginTop:'20px'}}>Lista destinatari mailing</FormTitle>
				<Grid item xs={12} style={{marginBottom:5}}>
					{ (id!=='new') &&
						<SimpleDataGrid
							upData={setDestinatariTmp}
							downData={destinatari}
							idField="idDestinatario"
							postUrl={"/mailing/destinatari"}
							columns={columnsDestinatari}
							setContextCommands={()=>{}}
							newRowTemplate={{mailing:id}}
							upHandleSalva={salvaDestinatari}
							bottomButtons={[
								<Button key='1' startIcon={<GroupAddIcon />} onClick={ () => setFiltroOpen(true) }>Aggiungi destinatari multipli</Button>
							]}
						/>
					 }
				</Grid>

			</Grid>
		</form>
	)
	
}