import SimpleDataGrid from './SimpleDataGrid';
export default SimpleDataGrid;

export function cellFloat() {
  return {
    valueGetter: parms=>{
      let value=parms.row[parms.field];
      if (value===null) return null;
      return value.toFixed();
    }
  }
}
