import React, {useEffect,useState,useCallback} from 'react';
import api from './../../lib/api2';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import ContextCommand from './../../components/ContextCommand';
import useNotification from './../../components/useNotification';
import Icon from './../../components/Icon';
import {nullZLSTabella} from './../../lib/dati';
import { DateTime } from "luxon";
import Stack from '@mui/material/Stack';

const SimpleDataGrid = React.forwardRef( ({idField,descriptionField="",getUrl,postUrl,columns,checkboxSelection=false,autoHeight=true,setContextCommands,upData=null,downData=null,getRowClassName=null,newRowTemplate={},rowActions=[],onRowClick,allowAdd=true,allowDelete=true,afterSalva,afterCancel,upHandleSalva,upHandleCancel,gridProps,onDataLoaded,marginBottom=50,bottomButtons=[]},ref)=>{
  const { pushNotification } = useNotification();
  const [rows,setRows]=useState([]);
  const [original,setOriginal]=useState([]);
  const [newID,setNewID]=useState(1);
  const [columnsEx,setColumnsEx]=useState([]);
  const [columnsDB,setColumnsDB]=useState([]);

  const caricaDati=useCallback(()=>{
    const setData=data=>{
      setRows(data);
      setOriginal(JSON.parse(JSON.stringify(data)));
      setNewID(1);
    }
    
    if (downData)  {
      setData(downData);
    } else {
      if (getUrl) {
        api.get(getUrl).then(data=>{
          setData(data);
          if (onDataLoaded) onDataLoaded(data);
        },error=>{
          pushNotification("Impossibile caricare i dati", "error");
        });
      }
    }
  },[getUrl,pushNotification,downData,onDataLoaded]);

  useEffect(()=>{
    if (upData) upData(rows);
  },[rows,upData])

  useEffect(()=>{
    const handleElimina = id => {
      const newRows=JSON.parse(JSON.stringify(rows));
      const r=newRows.find(r=>r[idField]===id);

      const msg="Confermare l'eliminazione" + (descriptionField && " di "+r[descriptionField]) + "?";
      if (!window.confirm(msg)) return;

      r._action='d';
      setRows(newRows);
    }

    if (allowDelete) rowActions.push({icon:<DeleteIcon/>,onClick:handleElimina,label:"Elimina",disabled:params=>params.row._action==='d' || params.row._action==='n'});

    const actionColumn={ field: 'actions', type: 'actions',  hide:rowActions.length===0, getActions: (params: GridRowParams) => [...rowActions.map(a=>
        <GridActionsCellItem icon={a.icon} onClick={()=>a.onClick(params.id)} label={a.label} disabled={a.disabled(params)} />,
      ),
      //...allowDelete ? <GridActionsCellItem icon={<DeleteIcon />} onClick={()=>handleElimina(params.id)} label="Elimina" disabled={params.row._action==='d' || params.row._action==='n'}  /> : [],
    ]}


    setColumnsEx([...columns,actionColumn]);//...(rowActions.length>0 ? actionColumn: []) ]);//

    setColumnsDB(columns.filter(c=>c.editable===true || c.field===idField));
    // eslint-disable-next-line
  },[columns,idField,rows]);

  const handleSalva = useCallback(() => {
    const righeModificate2=nullZLSTabella(rows.filter(r=>r._action));
    const righeModificate=righeModificate2.map(r=>{
      const ret={};
      columnsDB.forEach(c=>{
        //if (c.field.charAt(0)!=="_")
        let value=r[c.field];
        if (value)  {
          if (c.type==="number") value=parseFloat(value);
          if (c.type==="date") value=(new DateTime(value)).toISODate();
        }

        ret[c.field]=value;
      });
      ret._action=r._action;
      return ret;
    });
    if (postUrl==="") return;
    console.log(JSON.stringify(righeModificate))
    api.post(postUrl,righeModificate).then(ret=>{
      if (afterSalva) {
        if (afterSalva()) {
          pushNotification("Dati salvati","success");
          caricaDati();
        } else {
          pushNotification("Errore nel salvataggio - salvataggio parziale","error");
        }
      } else {
        pushNotification("Dati salvati","success");
        caricaDati();
      }
    }, error=>{
      pushNotification("Errore nel salvataggio","error");
    })
    // eslint-disable-next-line
  }, [pushNotification,rows,postUrl,caricaDati,afterSalva]);

  const handleCancel = useCallback(() => {
    if (window.confirm("Annullando verranno perse tutte le modifiche apportate. Confermi?")) {
      setRows(JSON.parse(JSON.stringify(original)));
      setNewID(1);
      if (afterCancel) afterCancel();
      pushNotification("Modifiche annullate","warning");
    }
  }, [original,pushNotification,afterCancel]);


  useEffect(()=>{
    if (upHandleSalva) upHandleSalva.current=handleSalva;
    if (upHandleCancel) upHandleCancel.current=handleCancel;
  },[upHandleSalva,upHandleCancel,handleSalva,handleCancel]);

  const handleAggiungi = () => {
    const newRow={[idField]:'new' + newID, ...newRowTemplate, _action:'n'};
    setNewID(newID+1);
    setRows([...rows,newRow]);
  }

  useEffect(()=>{
    if (!setContextCommands) return;
    const contextComands=[];
    contextComands.push(<ContextCommand key="1" onClick={handleCancel} icon="cancel">Annulla</ContextCommand>);
    if (!downData) contextComands.push(<ContextCommand key="2" onClick={handleSalva} icon="content-save">Salva</ContextCommand>);
    setContextCommands(contextComands);
  },[setContextCommands, handleSalva, handleCancel, downData]);

  useEffect(()=>{
    caricaDati();
  },[getUrl,caricaDati]);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      const newRows=JSON.parse(JSON.stringify(rows));
      const r=newRows.find(r=>r[idField]===id);
      const original=rows.find(r=>r[idField]===id);
      if (original[field]!==value)  {
        r[field]=value;
        if (r._action!=='n') r._action='e';
        setRows(newRows);
      }
    },
    [rows,idField],
  );

  const setValues=data=>{
    const newRows=JSON.parse(JSON.stringify(rows));
    data.forEach(dataRow=>{
      const r=newRows.find(r=>r[idField]===dataRow[idField]);
      for (let field in dataRow) {
        if (field!==idField) r[field]=dataRow[field];
      }
      if (r._action!=='n') r._action='e';
    });
    setRows(newRows);
  }

  React.useImperativeHandle(ref, () => ({
    setValues,
    setOption(params,iconOn='star',iconOff='star-outline',valueOn="1",valueOff="0")  {
      const onClick=params=>{
        const values=[{[idField]:params.id,[params.field]:valueOn}];
        const old=rows.find(r=>r[params.field]===valueOn);
        if (old) values.push({[idField]:old[idField],[params.field]:valueOff});
        setValues(values);
      }
      if (params.value===valueOn) return <div style={{display: "flex", justifyContent: "center", width:'100%'}}><Icon icon={iconOn} size="1.3rem"/></div>
      return <div style={{display: "flex", justifyContent: "center", width:'100%'}}><Icon icon={iconOff} size="1.3rem" onClick={()=>onClick(params)}/></div>
    }
  }))

  return (
    <div style={{marginBottom, height:'100%'}}>
      <DataGridPro
        rows={rows}
        columns={columnsEx}
        getRowId={r=>r[idField]}
        getRowClassName={r=>"action_"+r.row._action+" "+(getRowClassName && getRowClassName(r))}
        getCellClassName={r=>r.isEditable?"":"noedit"}
        onCellEditCommit={handleCellEditCommit}
        onRowClick={onRowClick}
        autoHeight={autoHeight}
        showCellRightBorder={true}
        showColumnRightBorder={true}
        {...gridProps}
        checkboxSelection={checkboxSelection}
      />
    <Stack direction='row' style={{clear:'left',float:'right',marginTop:5,marginRight:10}} >
      {allowAdd && <Button startIcon={<AddIcon />} onClick={handleAggiungi}>
        Aggiungi
      </Button> }
      { bottomButtons.map(b=>b)}
    </Stack>
    </div>
  );
});

export default SimpleDataGrid;

/*export function gridOption({params,iconOn='star',iconOff='star-outline',onClick,valueOn="1",valueOff="0"}) {
  if (params.value===valueOn) return <div style={{display: "flex", justifyContent: "center", width:'100%'}}><Icon icon={iconOn} size="1.3rem"/></div>
  return <div style={{display: "flex", justifyContent: "center", width:'100%'}}><Icon icon={iconOff} size="1.3rem" onClick={()=>onClick(params)}/></div>
}*/
