import React, { useState, useEffect } from 'react';
import api from 'lib/api2';
import useNotification from 'components/useNotification';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


export default function StrumentiStati({setTitle}) {
  const [isVisible, setIsVisible] = useState(true);
  const { pushNotification } = useNotification();
  const [filtroMatricola, setFiltroMatricola] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(prevState => !prevState);
    }, 500); // Cambia l'icona tra visibile e nascosta ogni 500 millisecondi

    return () => {
      clearInterval(interval);
    };
  }, []);

	useEffect(() => {
	    setTitle('Configurazione - Aggiornamento stati strumenti');
  }, [setTitle]);

  const handleFiltroMatricolaChange = (e) => {
    setFiltroMatricola(e.target.value);
  };

  const aggiorna = React.useCallback(() => {
    api.post("/strumenti/stati/"+filtroMatricola).then(d=>{
      pushNotification("Dati aggiornati", "success");
    }, error=>{
      pushNotification("Errore nell'aggiornamento", "error");
    });
	}, [pushNotification, filtroMatricola]);

	return (
    <div>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <h2>ATTENZIONE!
            {isVisible && <span role="img" aria-label="alert-icon">⚠️</span>}
            </h2>
          </Grid>
          <p>Usare questa funzione solamente per aggiornare lo stato della della disponibilità della matricola passata.<br/>
            (il carattere jolly è *)</p>
          <Grid item md={10} xs={9}>
            <TextField label="Filtro matricola" id="matricola" value={filtroMatricola} onChange={handleFiltroMatricolaChange} variant="outlined" fullWidth style={{marginBottom:'20px'}} />
          </Grid>
          <Grid item md={2} xs={3}>
            <Button onClick={()=>aggiorna()} style={{height:'75%'}} size="large" variant="outlined" fullWidth>Aggiorna</Button>
          </Grid>
      </Grid>
    </div>
  )
}
