import React, { useEffect, useState } from 'react';
import FormTextField from 'components/FormTextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useForm  } from 'react-hook-form';
import FormDateRangePickerSingle from 'components/FormDateRangePickerSingle';
import moment from 'moment';
import FormSelect from 'components/FormSelect';
import api from '../lib/api2';

const Filtro = React.memo(({filtra})=> {
    const [defaultValues] = useState( {cognome_like: "", idContratto: "", idDocumento: "", nome_like: "", tipoCliente: "", tipoContratto: "", dataScadenza_daterange: [moment().startOf('month'), moment().endOf('month')]} );
    const { control, handleSubmit } = useForm({mode: 'all', defaultValues});
    const [ tipiCliente, setTipiCliente ] = useState([]);
    const [ tipiContratto, setTipiContratto ] = useState([]);

    useEffect(()=>{
        filtra(defaultValues)
    }, [filtra, defaultValues])

    const optionsNull=[
        {value:'', label:'--Tutti--'},
        {value:'null', label:'No'},
        {value:'notnull', label:'Sì'},
    ]

    useEffect(() => {
		api.get('/clienti/tipi').then(data=>{
			setTipiCliente(data);
		});
        api.get('/contratti/tipi').then(data=>{
			setTipiContratto(data);
		});
  	}, []);

    return (
        <form onSubmit={handleSubmit(filtra)}>
            <Grid container spacing={1}>
                <FormTextField xs={12} md={1} control={control} name="nome_like" label="Nome" />
                <FormTextField xs={12} md={1} control={control} name="cognome_like" label="Cognome" />
                <FormSelect	xs={12} md={2} control={control} name='tipoCliente' label="Tipo cliente" options={[{ idTipoCliente: null, descrizioneTipoCliente: 'Tutti' }, ...tipiCliente]} valueField='idTipoCliente' labelField='descrizioneTipoCliente' />
                <FormSelect	xs={12} md={2} control={control} name='tipoContratto' label="Tipo contratto" options={[{ codice: null, descrizione: 'Tutti' }, ...tipiContratto]} valueField='codice' labelField='descrizione' />
                <FormTextField xs={6} md={1} control={control} name="idContratto" label="Numero contratto" />
                <FormDateRangePickerSingle control={control} xs={12} md={2} label='Scadenza' name='dataScadenza_daterange' />
                <FormSelect xs={12} md={2} control={control} name='idDocumento' label="Fatturata" options={optionsNull} />
                <Grid item xs={12} md={1}>
                    <Button type="submit" style={{height:56}} size="large" variant="outlined" fullWidth>Filtra</Button>         
                </Grid>
            </Grid>
        </form>
    )
});

export default Filtro;