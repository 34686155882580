import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api2';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ContextCommand from 'components/ContextCommand';
import useNotification from 'components/useNotification';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import FormTitle from 'components/FormTitle';
import { rimuoviNull, nullZLSTabella } from 'lib/dati';
import { useNavigate } from "react-router-dom";
import SimpleDataGrid from 'components/SimpleDataGrid';
import cellCheckbox01 from 'components/SimpleDataGrid/cellCheckbox01';
import cellCheckbox02 from 'components/SimpleDataGrid/cellCheckbox02';
import regexp from 'lib/regexp';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useReport from 'components/Report/useReport.js';
import PagamentiClienti from './PagamentiClienti.js';

const esigibilitaIva=[
	{ value: 'I', label: 'Esigibilità immediata' },
	{ value: 'D', label: 'Esigibilità differita' },
	{ value: 'S', label: 'Scissione dei pagamenti (o Split payment)' },
]

export default function Clienti({setTitle, setContextCommands}) {
	const { id } = useParams();
	const navigate = useNavigate();
	const { pushNotification } = useNotification();
	const { control, handleSubmit, /*watch, trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const [isLoaderOpen, setLoaderOpen] = useState(false);
	const [tipiCliente, setTipiCliente] = useState([]);
	const [recapitiCliente, setRecapitiCliente] = useState([]);
	const [recapitiMailPrincipale, setRecapitiMailPrincipale] = useState([]);
	const [recapitiTelefonoPrincipale, setRecapitiTelefonoPrincipale] = useState([]);
	const [recapitiCellularePrincipale, setRecapitiCellularePrincipale] = useState([]);
	const [indirizziCliente, setIndirizziCliente] = useState([]);
	const [indirizziClienteFD, setIndirizziClienteFD ] = useState([]);
	const [titoli, setTitoli] = useState([]);
	const [defaultTitolo, setDefaultTitolo] = useState('');
	const { openReport } = useReport();
	const salvaPagamenti = React.useRef();

	const colonneRecapitiCliente = [
		{ field: 'tipologia', headerName: 'Tipologia', width: 250, editable: true, type:"singleSelect", valueOptions:['', 'Cellulare', 'E-mail', 'Fax', 'Telefono']},
		{ field: 'recapito', headerName: 'Recapito', flex:1, editable: true, },
		{ field: 'note', headerName: 'Note', width: 400, editable: true, },
		{ field: 'invio', headerName: 'Invio', width: 80, editable: false, ...cellCheckbox02() },
	];

	const colonneIndirizziCliente = [
		{ field: 'descrizioneIndirizzo', headerName: 'Descrizione', width: 130, editable: true, },
		{ field: 'denominazioneIndirizzo', headerName: 'Denominazione', width: 280, flex:1, editable: true, },
		{ field: 'via', headerName: 'Via', width: 280, editable: true, },
		{ field: 'civico', headerName: 'Civico', width: 80, editable: true, },
		{ field: 'cap', headerName: 'CAP', width: 80, editable: true, },
		{ field: 'localita', headerName: 'Località', width: 80, editable: true, },
		{ field: 'provincia', headerName: 'Provincia', width: 80, editable: true, },
		{ field: 'nazione', headerName: 'Nazione', width: 80, editable: true, },
		{ field: 'piano', headerName: 'Piano', width: 60, editable: true, },
		{ field: 'obsoleto', headerName: 'Obsoleto', width: 80, editable: true, ...cellCheckbox01() },
	];

	const stampa = useCallback ( () => {
		var url="/report/clienti/"+id;
		openReport(url);
	}, [id, openReport]);

	const elimina = useCallback(() => {
		const confermaEliminazione = window.confirm("Sei sicuro di voler eliminare questo cliente?");
		if (confermaEliminazione) {
		  api.delete("/clienti/"+id, []).then(r=>{
			pushNotification("Dati cancellati", "success");
			navigate("/clienti/");
		  }, error=>{
			pushNotification("Errore nella cancellazione", "error");
		  });
		  return true;
		}
		return false;
	}, [id, pushNotification, navigate]);

	useEffect(() => {
		setLoaderOpen(true);
  	}, []);

	useEffect(() => {
		const caricaTabelle=async ()=>{
			await api.get('/clienti/titoli?sort=titolo').then(data=>{
				setTitoli(data);
			});
		}
		caricaTabelle();
  	}, []);

	useEffect(()=>{
		if (id==='new') {
			setValue('_action', 'n');
			setLoaderOpen(false);
		} else {
			api.get(`/clienti/${id}`).then(data=>{
				if (data.length!==1) {
					alert('Errore nel caricamento dei dati');
				} else {
					const cliente = rimuoviNull(data)[0];
					cliente._action="e";
					reset(cliente);
					setTitle('Cliente: '+cliente.cognome+' '+cliente.nome);
					setDefaultTitolo(cliente.titolo);
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue, setTitle, reset]);
	
	useEffect(() => {
		const recapitiMail = recapitiCliente.filter(d => d.tipologia === "E-mail").map(d => ({ ...d }));
		const recapitiTelefono = recapitiCliente.filter(d => d.tipologia === "Telefono").map(d => ({ ...d }));
		const recapitiCellulare = recapitiCliente.filter(d => d.tipologia === "Cellulare").map(d => ({ ...d }));
		setRecapitiMailPrincipale(recapitiMail);
		setRecapitiTelefonoPrincipale(recapitiTelefono);
		setRecapitiCellularePrincipale(recapitiCellulare);
	}, [recapitiCliente]);

	useEffect(()=>{
		const indirizzi=indirizziCliente.map(d=>({...d,indirizzoCompilato: (d.via||'') +', '+ (d.civico||'') +' - '+ (d.cap||'') +' '+ (d.localita||'') +' ('+(d.provincia||'')+')'}))
		indirizzi.push({idIndirizzo:'',indirizzoCompilato:'--ND--'})
		setIndirizziClienteFD(indirizzi);

		if (indirizzi.length > 1) {
			setValue('indirizzoFatturazione', indirizzi[0].idIndirizzo);
			setValue('indirizzoDestinazione', indirizzi[1].idIndirizzo);
		}

	}, [indirizziCliente, setValue]);
	
	useEffect(() => {
		var indirizzoDestinazione;
		var indirizzoFatturazione

		const salva = async data=>{
			if (data['cognome'] || data['nome']) {
				if (!data.indirizzoFatturazione || !data.indirizzoDestinazione) {
					const conferma = window.alert("Gli indirizzi di fatturazione e destinazione non sono impostati.");
					if (!conferma) {
						return; // Non proseguire con il salvataggio
					}
				}
				
				indirizzoDestinazione=data.indirizzoDestinazione;
				indirizzoFatturazione=data.indirizzoFatturazione;
				
				delete data.indirizzoFatturazione;
				delete data.indirizzoDestinazione;

				const json = nullZLSTabella([data]);

				api.post("/clienti", json).then(async idCliente=>{
					const errors=[];

					for (let i=0;i<indirizziCliente.length;i++)	{
						const indirizzo=nullZLSTabella([indirizziCliente[i]])[0];
						if (indirizzo._action)	{
							indirizzo.cliente=idCliente;
							// eslint-disable-next-line
							await api.post("/clienti/indirizzi", indirizzo).then(idIndirizzo=>{
								if (indirizzoFatturazione===indirizzo.idIndirizzo) {
									indirizzoFatturazione=JSON.parse(idIndirizzo);
									setValue('indirizzoFatturazione',indirizzoFatturazione);
								}
								if (indirizzoDestinazione===indirizzo.idIndirizzo) {
									indirizzoDestinazione=JSON.parse(idIndirizzo);
									setValue('indirizzoDestinazione',indirizzoDestinazione);
								}
							}, error=>{
								console.error(error);
								errors.push("Indirizzo" + indirizzo.idIndirizzo + " via " + indirizzo.via);
							});
						}
					}

					const indirizzi=[{idCliente,indirizzoDestinazione,indirizzoFatturazione,_action:'e'}];
					await api.post("/clienti", nullZLSTabella(indirizzi)).then(d=>{
						//pushNotification("Dati salvati", "success"); MA DAI PUOI MESCOLARE 8000 NOTIFICHE DI OK/ERRORE CHE VENGONO VISUALIZZATE CONTEMPORANEAMENTE?
					}, error=>{
						errors.push("Indirizzo destinazione/fatturazione");
						console.error(error);
					});

					recapitiCliente.forEach((indirizzo,index)=>{
						recapitiCliente[index].cliente=idCliente;
					})
					await api.post("/clienti/recapiti", nullZLSTabella(recapitiCliente)).then(d=>{
						pushNotification("Dati salvati", "success");
					}, error=>{
						errors.push("Recapiti");
						console.error(error);
					});

					if (errors.length===0)	{
						pushNotification("Dati salvati", "success");
					}	else	{
						pushNotification("Dati cliente salvati, ma errori in: " + errors.join(", "), "error");
					}
					
					navigate("/clienti/"+idCliente);
				}, error=>{
					//const descrizioneErrore = error.text();
					console.error(error);
					pushNotification("Errore nel salvataggio del cliente", "error");
				});
			} else {
				alert("Inserire almeno il cognome o nome.");
			}
		}
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/clienti") }>Indietro</ContextCommand>);
		contextCommands.push(<ContextCommand key="2" icon="delete" onClick={ elimina }>Elimina</ContextCommand>);
		// Aggiungi il pulsante "Stampa" solo se l'ID del cliente non è "new"
		if (id !== 'new') {
			contextCommands.push(<ContextCommand key="3" icon="file" onClick={handleSubmit(data => stampa(data))}>Stampa</ContextCommand>);
		}
		contextCommands.push(<ContextCommand key="4" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>);
		setContextCommands(contextCommands);
  	}, [id, setContextCommands, handleSubmit, navigate, pushNotification, indirizziCliente, recapitiCliente, elimina, stampa, setValue]);

	useEffect(() => {
		api.get('/clienti/tipi').then(data=>{
			setTipiCliente(data);
		});
  	}, [id]);

	return (
		<form>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={1}>
				<FormTitle variant="h6">Dettagli</FormTitle>
				<FormTextField control={control} name="idCliente" label="ID" disabled={true} />
				<FormSelect control={control} name='tipoCliente' /* rules={{required: true}} */ label="Tipo cliente" options={tipiCliente} valueField='idTipoCliente' labelField='descrizioneTipoCliente' />
				
				<Grid item xs={12} md={2}>
					<Autocomplete
						id="titolo"
						freeSolo
						options={titoli.map((option) => option.titolo)}
						renderInput={(params) => <TextField {...params} label="Titolo" />}
						value={defaultTitolo}
						onChange={(event, newValue) => {
							setValue('titolo', newValue || ''); // Aggiorna il valore del titolo nel form
						}}
						onInputChange={(event, newValue) => {
							setValue('titolo', newValue); // Aggiorna il valore del titolo nel form quando l'utente inserisce manualmente
						}}
					/>
				</Grid>
				<FormTextField xs={12} md={5} control={control} name="cognome" label="Cognome" />
				<FormTextField xs={12} md={5} control={control} name="nome" label="Nome" />
				
				<FormTextField control={control} name="nascitaData" label="Data di nascita" type="date" />
				<FormTextField control={control} name="nascitaLuogo" label="Luogo di nascita" />

				<FormTitle variant="h6">Indirizzi</FormTitle>

				<Grid item xs={12} md={12}>
					<SimpleDataGrid
						upData={setIndirizziCliente}
						idField="idIndirizzo"
						getUrl={id !== 'new' ? `/clienti/${id}/indirizzi` : ''}
						columns={colonneIndirizziCliente}
						setContextCommands={() => {}}
						newRowTemplate={{ cliente: id, nazione: "IT", obsoleto: "0" }}
					/>
				</Grid>
				
				<FormSelect xs={12} md={6} control={control} name='indirizzoFatturazione' label="Indirizzo fatturazione" options={indirizziClienteFD} valueField='idIndirizzo' labelField="indirizzoCompilato" />
				<FormSelect xs={12} md={6} control={control} name='indirizzoDestinazione' label="Indirizzo destinazione" options={indirizziClienteFD} valueField='idIndirizzo' labelField="indirizzoCompilato" />
				
				<FormTitle variant="h6">Dati di fatturazione</FormTitle>
				<FormTextField control={control} name="cf" label="CF" /*rules={{pattern:regexp.cf}}*/ />
				<FormTextField control={control} name="pi" label="PI" /*rules={{pattern:regexp.pi}}*//>

				<FormTextField xs={6} md={3} control={control} name="sdiCodice" label="Codice univoco" rules={{pattern:regexp.codiceUnivoco}}/>
				<FormTextField xs={6} md={6} control={control} name="sdiPec" label="PEC (invio fatture)" rules={{pattern:regexp.email}} />

				<FormSelect xs={12} md={3} control={control} name='esigibilita' label="Esigibilità IVA" options={esigibilitaIva} valueField='value' labelField='label' />

				<FormTitle variant="h6">Recapiti</FormTitle>
				<Box sx={{ width: '100%' }}>
					<SimpleDataGrid
						upData={setRecapitiCliente}
						idField="idRecapitoCliente"
						getUrl={id!=='new' ? "/clienti/"+id+"/recapiti" : ""}
						columns={colonneRecapitiCliente}
						setContextCommands={()=>{}}
						newRowTemplate={{cliente:id}}
					/>
				</Box>

				<FormSelect xs={12} md={4} control={control} name='mailPrincipale' label="Mail principale" options={recapitiMailPrincipale} valueField='idRecapitoCliente' labelField="recapito" />
				<FormSelect xs={12} md={4} control={control} name='telefonoPrincipale' label="Telefono principale" options={recapitiTelefonoPrincipale} valueField='idRecapitoCliente' labelField="recapito" />
				<FormSelect xs={12} md={4} control={control} name='cellularePrincipale' label="Cellulare principale" options={recapitiCellularePrincipale} valueField='idRecapitoCliente' labelField="recapito" />

				<FormTitle variant="h6">Note</FormTitle>
				<FormTextField xs={12} md={12} control={control} name="note" label="Note" controlProps={{ multiline:true, rows:3 }} />
				
				<PagamentiClienti idCliente={id} salvaPagamenti={salvaPagamenti} />

			</Grid>
    	</form>
	);
}
