import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import api from 'lib/api2';
import { apiGet } from 'lib/api';
import useNotification from 'components/useNotification';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function Archivio({tipo, id}) {
  const [ files, setFiles ] = useState([]);
  const { pushNotification } = useNotification();

  const caricaFiles = useCallback(() => {
    if (id !== 'new') {
      api.get('/archivio/'+tipo+'/'+id).then(data=>{
        setFiles(data);
      });
    }
  }, [id, tipo]);

  useEffect(() => {
		caricaFiles();
  }, [caricaFiles]);

  const scaricaFile = filename=>{

    apiGet("/archivio/"+tipo+"/"+id+"/"+filename, data=>{      
      // Contenuto del file
      const fileContent = data;
      // Creazione del Blob
      const blob = new Blob([fileContent], { type: 'application/octet-stream' });
      // Creazione dell'URL oggetto
      const blobUrl = URL.createObjectURL(blob);
      // Creazione e simulazione del clic sul link
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // Pulizia dell'URL oggetto dopo il download
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(downloadLink);
    }, 'blob');

	}

  const cancellaFile = filename=>{
    if (window.confirm("Vuoi cancellare il file? (Verrà cancellato immediatamente)")) {
      api.delete( "/archivio/"+tipo+"/"+id+"/"+filename ).then(r=>
        {
          caricaFiles();
        }, error=>{
          pushNotification("Errore nell'eliminazione del file.", "error");
        }
      );
    }
	}

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    // Controllo sulla dimensione del file (in byte)
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    const warnSizeInBytes = 3 * 1024 * 1024; // 3MB
  
    if (fileSizeInBytes > maxSizeInBytes) {
      alert('Il file è troppo grande (massimo 10MB).');
      return;
    } else if (fileSizeInBytes > warnSizeInBytes) {
      // Avviso se il file è sopra i 3MB, ma procedi con l'upload
      alert('Il file è grande, ma verrà comunque caricato.');
    }
  
    var reader = new FileReader();
    reader.onload = () => {
      const data = { name: file.name, data: reader.result };
      api.post("/archivio/" + tipo + "/" + id, data).then(() => caricaFiles());
    };
    reader.onerror = (error) => {
      alert('Errore nella lettura del file (conversione base64).');
    };
    reader.readAsDataURL(file);
  };

  return (
		<Grid container spacing={1}>
			<Grid item xs={12} md={6} xl={6} style={{marginBottom:5}}>
        <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
          Carica nuovo file...
          <input hidden multiple type="file" onChange={handleFileChange} />
        </Button>
        
        <List>
        {files.map(filename => (
          <ListItem
            key={filename}
            secondaryAction={
              <>
                <IconButton edge="end" aria-label="download" onClick={ ()=>scaricaFile(filename) }>
                  <DownloadIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={ ()=>cancellaFile(filename) }>
                  <DeleteIcon />
              </IconButton>
              </>
            }
          >
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={filename}/>
        </ListItem>
        ))}
        </List>

			</Grid>
		</Grid>
  )
}