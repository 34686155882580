import React, { useState, useEffect } from 'react';
import api from 'lib/api2';
import useNotification from 'components/useNotification';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


export default function AggiornamentoScadenze({setTitle}) {
  const [isVisible, setIsVisible] = useState(true);
  const { pushNotification } = useNotification();
  const [filtroContratti, setFiltroContratti] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(prevState => !prevState);
    }, 500); // Cambia l'icona tra visibile e nascosta ogni 500 millisecondi

    return () => {
      clearInterval(interval);
    };
  }, []);

	useEffect(() => {
	    setTitle('Configurazione - Aggiornamento scadenze contratti');
  }, [setTitle]);

  const handleFiltroContrattiChange = (e) => {
    setFiltroContratti(e.target.value);
  };

  const aggiorna = React.useCallback(() => {
    api.post("/contratti/scadenze/"+filtroContratti).then(d=>{
      pushNotification("Dati aggiornati", "success");
    }, error=>{
      pushNotification("Errore nell'aggiornamento", "error");
    });
	}, [pushNotification, filtroContratti]);

	return (
    <div>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <h2>ATTENZIONE!
            {isVisible && <span role="img" aria-label="alert-icon">⚠️</span>}
            </h2>
          </Grid>
          <p>Usare questa funzione solamente per aggiornare le scadenze di un contratto (o di tutti i contratti).<br/>
            (il carattere jolly è *)</p>
          <Grid item md={10} xs={9}>
            <TextField label="Filtro scadenza" id="scadenza" value={filtroContratti} onChange={handleFiltroContrattiChange} variant="outlined" fullWidth style={{marginBottom:'20px'}} />
          </Grid>
          <Grid item md={2} xs={3}>
            <Button onClick={()=>aggiorna()} style={{height:'75%'}} size="large" variant="outlined" fullWidth>Aggiorna</Button>
          </Grid>
      </Grid>
    </div>
  )
}
