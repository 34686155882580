import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from 'lib/api2';
import Filtro from './Filtro';
import { useNavigate } from "react-router-dom";
import ContextCommand from 'components/ContextCommand';
import { DateTime } from "luxon";
import { DataGridPro, useGridApiRef  } from '@mui/x-data-grid-pro';
import { unstable_debounce as debounce } from '@mui/utils';
import qsBuilder from 'lib/qsBuilder';

export default function ContrattiElenco({setTitle, setContextCommands}) {
	let {tipo} = useParams();
	const navigate = useNavigate();
	const apiRef = useGridApiRef();
	const [rowCount, setRowCount] = useState(0);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [filtro, setFiltro] = useState({});
	const [columns, setColumns] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		var titolo = "";
		switch(tipo) {
			case "v":
				titolo = "Vendite";
				break;
			case "n":
				titolo = "Noleggi";
				break;
			case 'r':
				titolo = "Riparazioni";
				break;
			case 'c':
				titolo = "Noleggi per concerti";
				break;
			default:
				titolo = "Contratti";
				break;
		  }
		setTitle(titolo);
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/contratti/"+tipo+"/new") }>Inserisci</ContextCommand>);
		setContextCommands(contextCommands);
	}, [setTitle, setContextCommands, navigate, tipo]);

	useEffect(()=>{
		var columnsNew=[];
		/*if ( tipo==="v" ) {
			columnsNew=[
				{ field: 'nominativo', headerName: 'Nominativo', flex:2, minWidth:200 },
				{ field: 'idContratto', headerName: 'Numero', width: 80 },
				{ field: 'strumento', headerName: 'Matricola', width: 110 },
				{
					field: 'valore',
					headerName: 'Importo',
					width: 100,
					type:'number',
					valueFormatter: params => {
					  return new Intl.NumberFormat('it-IT', {
						style: 'currency',
						currency: 'EUR'
					  }).format(params.value);
					}
				},
				{ field: 'data', headerName: 'Data', width: 120, valueFormatter: params => DateTime.fromSQL(params?.value).toLocaleString({day:'2-digit', month:'2-digit', year:'numeric'}) },
			];
		} else {*/
			columnsNew=[
				{ field: 'nominativo', headerName: 'Nominativo', flex:2 },
				{ field: 'idContratto', headerName: 'Numero', width: 110, align:'right' },
				{ field: tipo === 'v' || tipo=== 'r' ? 'prezzo' : 'valore', headerName: 'Importo', width: 120, type: 'number', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value) } },
				{ field: 'data', headerName: 'Data', width: 120, valueFormatter: params => DateTime.fromSQL(params?.value).toLocaleString({ day: '2-digit', month: '2-digit', year: 'numeric' }) },
				{ field: 'strumento', headerName: 'Matricola', width: 140 },
				{ field: 'descrizioneMarca', headerName: 'Marca', width: 150 },
				{ field: 'modello', headerName: 'Modello', width: 150 },
				{ field: 'destinazione', headerName: 'Destinazione', flex: 1, minWidth: 200 },

			];
		//}

		if (!tipo) columnsNew.unshift({ field: 'descrzioneTipoContratto', headerName: 'Tipo', width: 200 });
		setColumns(columnsNew);
	}, [tipo])

	useEffect(()=>{
		const r=[];
		for (const [key, value] of Object.entries(filtro)) {
			if (!value) continue;
			if (key==='nome' || key==='cognome' || key==='idContratto' || key==='strumento') {
				if (value) r.push(`${key}=%25${value}%25|like`);
			} else if (key==='dataDa' && key!=='dataA') {
				r.push("data="+filtro.dataDa+"|>");
			} else if (key!=='dataDa' && key==='dataA') {
				r.push("data="+filtro.dataA+"|<");
			} else if (key==='dataDa' && key==='dataA') {
				r.push("data="+filtro.dataDa+","+filtro.dataA+"|between");
			} else {
				r.push(key+"="+value);
			}
		}
		qsBuilder(r.join("&"));
	}, [filtro]);

	const onRowClick = (r) => {
		navigate("/contratti/"+r.row.tipoContratto+"/"+r.row.idContratto);
	}

	const getRowCount = useCallback(async ()=>{
		const url = "/contratti/count?" + qsBuilder(filtro) + '&tipoContratto='+tipo;

		const c = await api.get(url);
		setRowCount(parseInt(c[0]['conteggio'], 10 ));
	}, [filtro, tipo])

	const handleFetchRows = React.useCallback(async ({firstRowToRender, lastRowToRender}) => {
		const limit = lastRowToRender-firstRowToRender;
		var url="/contratti?sort=year(data) desc, idContratto desc, strumento desc&limit="+limit+"&offset="+firstRowToRender+"&"+qsBuilder(filtro);
		if (tipo) {
			url+='&tipoContratto='+tipo;
		}
		api.get(url).then(righe=>{
			apiRef.current.unstable_replaceRows(firstRowToRender, righe);
		});
	}, [apiRef, tipo, filtro]);

	const debouncedHandleFetchRows = React.useMemo(
		() => debounce(handleFetchRows, 200),
		[handleFetchRows],
	);

	const filtra = async filtro=>{
		apiRef.current.setRows([]);
		await getRowCount();
		setFiltro(filtro)
	}

	useEffect(() => {
		setLoading(false);
		getRowCount();
		handleFetchRows({firstRowToRender:0, lastRowToRender:100});
  	}, [handleFetchRows, getRowCount]);
	
	return (
		<div style={{marginBottom:50, height:'85vh'}}>
			<Filtro filtra={filtra} />
			<DataGridPro
				loading={loading}
				columns={columns}
				rows={[]}
				apiRef={apiRef}
				hideFooterPagination
				sortingMode="server"
				filterMode="server"
				rowsLoadingMode="server"
				rowCount={rowCount}
				onFetchRows={debouncedHandleFetchRows}
				experimentalFeatures={{
					lazyLoading: true
				}}
				style={{height:'100%'}}
				getRowId={r => r.idContratto}
				getCellClassName={r => "noedit"}
				showCellRightBorder={false}
        		showColumnRightBorder={true}
				paginationMode="server"
				keepNonExistentRowsSelected
				onSelectionModelChange={(newRowSelectionModel) => {
					setRowSelectionModel(newRowSelectionModel);
				}}
				rowSelectionModel={rowSelectionModel}
				onRowClick={r=>onRowClick(r)}
				sx={{
					cursor:'pointer',
					".row-green": {
						background: "lightgreen",
						color: "white !important"
					  }
				}}
				throttleRowsMs={1000}
			/>
		</div>
	);
}