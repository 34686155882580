import React, { useEffect, useState } from 'react';
import FormTextField from 'components/FormTextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useForm  } from 'react-hook-form';
import api from 'lib/api2';
import FormDateRangePickerSingle from 'components/FormDateRangePickerSingle';
import FormSelect from 'components/FormSelect';

export default function Filtro({filtra}) {
    const { control, handleSubmit } = useForm({mode: 'all'});
    const [documentiTipi,setDocumentiTipi]=useState([]);
    const optionsBool=[
        {value:'',label:'--Tutti--'},
        {value:'0',label:'No'},
        {value:'1',label:'Sì'},
    ]

    const optionsInvioSDI=[
        {value:'',label:'--Tutti--'},
        {value:'null',label:'No'},
        {value:'notnull',label:'Sì'},
    ]

    useEffect(()=>{
        api.get('/documenti/tipi').then(data=>{
			setDocumentiTipi(data);
		});
    },[]);

    return (
        <form onSubmit={handleSubmit(filtra)}>
            <Grid container spacing={1}>
                <FormTextField xs={12} md={1} control={control} name="nome_like" label="Nome" />
                <FormTextField xs={12} md={1} control={control} name="cognome_like" label="Cognome" />
                <FormTextField xs={6} md={1} control={control} name="numero" label="Numero" />
                <FormDateRangePickerSingle control={control} xs={12} md={3} label='Data' name='data_daterange' />
                <FormSelect xs={12} md={3}control={control} name='tipoDocumento' label="Tipo documento" options={documentiTipi} valueField='codiceTipoDocumento' labelField='descrizioneTipoDocumento' />
                <FormSelect xs={12} md={1} control={control} name='pagata' label="Pagata" options={optionsBool} />
                <FormSelect xs={12} md={1} control={control} name='feUploadFileName' label="Invio SDI" options={optionsInvioSDI} />
                <Grid item xs={12} md={1}>
                    <Button type="submit" style={{height:56}} size="large" variant="outlined" fullWidth>Filtra</Button>         
                </Grid>
            </Grid>
        </form>
    )
}