import React, { useEffect } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function Strutture({setTitle,setContextCommands}) {

	const columns=[
    { field: 'idStruttura', headerName: 'ID', width: 90, type:'number' },
    { field: 'descrizioneStruttura', headerName: 'Struttura', editable:true, flex:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Strutture');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="idStruttura"
      getUrl="/strumenti/strutture?sort=descrizioneStruttura"
      postUrl="/strumenti/strutture"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
