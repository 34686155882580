import React from 'react';
import useReport from './../../components/Report/useReport.js';
import Loader from './../../components/Loader';

export default function Report() {
  const { loader } = useReport();

  if (loader)  return (
    <Loader open={true} />
  )

  return "";
}
