import React, { useState, useEffect } from 'react';
import ContextCommand from 'components/ContextCommand';
import Grid from '@mui/material/Grid';
import SimpleDataGrid from 'components/SimpleDataGrid';
import { DateTime } from "luxon";
import FormTextField from 'components/FormTextField';
import FormTitle from 'components/FormTitle';
import { useForm  } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Loader from '../components/Loader';
import { useNavigate } from "react-router-dom";

export default function MailingElenco({setTitle, setContextCommands}) {
	const navigate = useNavigate();
	const { control, handleSubmit, /*watch, trigger, getValues, setValue, reset, formState: { errors }*/ } = useForm({mode: 'all', defaultValues: { dataDa: DateTime.now().startOf('year').toISODate().toString(), dataA: DateTime.now().endOf('month').toISODate().toString() }});
	const [ url, setUrl ] = useState();
	const [ isLoaderOpen, setLoaderOpen] = useState(false);

	const columns = [
		{ field: 'idMailing', headerName: 'ID', width: 80 },
		{ field: 'subject', headerName: 'Oggetto',  flex:1 },
		{ field: 'data', headerName: 'Data', width: 120,  type:'date', valueGetter:params=>DateTime.fromSQL(params.value).toLocaleString({day:'2-digit', month:'2-digit', year:'numeric'}) },
	]

	useEffect(() => {
		setTitle('Mailing');
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/mailing/new") }>Inserisci</ContextCommand>);
		setContextCommands(contextCommands);
		caricamentoMailing();
	}, [setTitle, handleSubmit, navigate, setContextCommands]);

	const caricamentoMailing = () => {
		var dataDa = DateTime.now().startOf('year').toFormat('yyyy-MM-dd');
		var dataA = DateTime.now().endOf('year').toFormat('yyyy-MM-dd');
		var urlNew = "/mailing?data="+dataDa+","+dataA+"|between&sort=data";
		setLoaderOpen(true);
		setUrl(urlNew);
	}

	const ricercaBTN = (data) => {
		var urlNew = "/mailing?data="+data.dataDa+","+data.dataA+"|between&sort=data";
		setLoaderOpen(true);
		setUrl(urlNew);
	}
	
	return (
		<form>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={1}>
				<FormTitle variant="h6">Filtro</FormTitle>
				<FormTextField xs={6} md={5} control={control} name="dataDa" label="Data da" type="date" required />
				<FormTextField xs={6} md={5} control={control} name="dataA" label="Data a" type="date" required />
				<Grid item xs={1} md={1} style={{marginBottom:5}}>
					<IconButton size="large" onClick={ handleSubmit(data=>ricercaBTN(data)) } >
						<SearchIcon />
					</IconButton>
				</Grid>
				<Grid item xs={12} style={{marginBottom:5}}>
					<SimpleDataGrid
						idField="idMailing"
						getUrl={url}
						columns={columns}
						setContextCommands={()=>{}}
						onRowClick={r=>navigate("/mailing/"+r.id)}
						onDataLoaded={()=>setLoaderOpen(false)}
						newRowTemplate={{data:DateTime.now().toFormat('yyyy-MM-dd')}}
						allowAdd={false}
						allowDelete={false}
					/>
				</Grid>
			</Grid>
		</form>
	)
	
}
	