/*******************************************************************************
Formato nome parametri:

nomeCampo_operatore

*******************************************************************************/
import { isObject } from "./dati";

export default function qsBuilder(filter){
    if (!filter) return '';

    const conditions=[];
    const filter2={};

    Object.keys(filter).forEach(k=>{
        if (isObject(filter[k]))    {
            Object.keys(filter[k]).forEach(sk=>{
                filter2[k+':'+sk]=filter[k][sk];
            });
        }   else    {
            filter2[k]=filter[k];
        }
    });

    Object.keys(filter2).forEach(k=>{
        const value=filter2[k];
        if ( (!Array.isArray(value) && value) || (Array.isArray(value) && value.some(v=>v)) )  {
            const [field,operator]=k.split('_');
            var cond;
            
            if (!operator) {
                cond=value;
            } else if(operator==='like')    {
                cond=`%${value}%|${operator}`;
            } else if(operator==='daterange' && Array.isArray(value) && value.length>=2)  {
                if (value[0] && value[1] && value[0].isValid() && value[1].isValid())   cond=`${value[0].format('YYYY-MM-DD')},${value[1].format('YYYY-MM-DD')}|between`;
                else if (value[0] && value[0].isValid())    cond=`${value[0].format('YYYY-MM-DD')}|>=`;
                else if (value[1] && value[1].isValid())    cond=`${value[1].format('YYYY-MM-DD')}|<=`;
            } else {
                let newop=operator;
                if (newop==='lss') newop='<';
                else if (newop==='leq') newop = '<=';      
                else if (newop === 'gtr') newop = '>';     
                else if (newop === 'geq') newop = '>=';     

                cond = `${value}|${newop}`;
            }
            conditions.push(field.replace(':','__')+'='+encodeURIComponent(cond));
        }   
    });

    return conditions.join('&');
}