import React from 'react';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import DrawerContent from './DrawerContent';
import '@mdi/font/css/materialdesignicons.min.css';
import useLayoutStyles from './../../appearance/layoutStyles';
import menu from './menu';


export default function MainMenu(props) {
  const classes = useLayoutStyles();

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer classes={{ paper: classes.drawerPaper }} open={props.mobileOpen} onClose={props.handleDrawerToggle} ModalProps={{ keepMounted: true }}>
          <DrawerContent menu={menu} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer classes={{ paper: classes.drawerPaper }} open variant="permanent">
          <DrawerContent menu={menu} />
        </Drawer>
      </Hidden>
    </nav>
  );
}
