import React, {useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";

export default function MenuItem(m) {
  const navigate = useNavigate();
  const [subMenuOpen,setSubMenuOpen]=useState(false);
  const [contextMenuPosition,setContextMenuPosition]=useState(null);

  const depth=m.depth||0;
  const paddingLeft=(depth*1.5+0.5)+"em";
  if (m.text==="-") return <Divider component="li" variant={depth===0?"fullWidth":"inset"} />;

  const handleContextMenu=(event)=>{
    if (m.subMenu) return;
    event.preventDefault();
    setContextMenuPosition({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }

  const handleClick=(e,newWindow=false)=>{
    setSubMenuOpen(false);
    if (m.subMenu) {
      setSubMenuOpen(!subMenuOpen);
    } else {
      var url=m.url;
      if (m.url==="/documenti") {
        window.open(url);
      }
      if (newWindow)  {
        window.open(url);
      } else {
        navigate(url);
        //document.location.href=url;
      }
    }
  }

  return (<div>
    <ListItem button onClick={handleClick} onContextMenu={handleContextMenu} style={{paddingLeft:paddingLeft}}>
      <ListItemIcon>
        <span className={"mdi mdi-" + m.icon} style={{"fontSize": "1.6em"}}></span>
      </ListItemIcon>
      <ListItemText primary={m.text} />
      {m.subMenu &&  (subMenuOpen ? <ExpandLess /> : <ExpandMore />)}
    </ListItem>
    {m.subMenu &&  (
      <Collapse in={subMenuOpen} unmountOnExit>
        <List disablePadding>
          {m.subMenu.map( (sm,si) => (
            <MenuItem {...sm} key={si} depth={depth+1} />
          ))}
        </List>
      </Collapse>
    )}
    <Menu
      keepMounted
      open={contextMenuPosition!==null}
      onClose={()=>setContextMenuPosition(null)}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuPosition
          ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
          : undefined
      }
    >
      <MuiMenuItem onClick={handleClick}>Apri</MuiMenuItem>
      <MuiMenuItem onClick={e=>handleClick(e,true)}>Apri in un'altra scheda</MuiMenuItem>
    </Menu>
  </div>);
}
