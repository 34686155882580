import React, { useEffect } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function IVA({setTitle,setContextCommands}) {

	const columns=[
    { field: 'codiceIVA', headerName: 'Codice', width: 80 },
    { field: 'descrizioneIva', headerName: 'Descrizione', width: 700, editable:true, flex:false },
    { field: 'aliquotaIva', headerName: 'Aliquota %', editable:true, flex:true, type:'number', valueGetter:params=>parseInt(params.value) },
    { field: 'natura', headerName: 'Natura esenzione', editable:true, flex:true },
    { field: 'riferimentoNormativo', headerName: 'Riferimento normativo', editable:true, flex:true },
    { field: 'obsoleta', headerName: 'Obsoleta', editable:true, flex:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - IVA');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="codiceIVA"
      getUrl="/iva"
      postUrl="/iva"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
