import React, { useEffect } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function Colori({setTitle,setContextCommands}) {

	const columns=[
    { field: 'idColore', headerName: 'ID', width: 90, type:'number' },
    { field: 'descrizioneColore', headerName: 'Colore', editable:true, flex:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Colori');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="idColore"
      getUrl="/strumenti/colori?sort=descrizioneColore"
      postUrl="/strumenti/colori"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
